$grid-size: 512px;

:root {
  background-color: var(--background-color);
  color: var(--color-a);
  font-size: 16px;
  transition: all 200ms ease-in-out;
}

::selection {
  background: var(--accent-a);
}

:root {
  min-height: 100vh;
}

body {
  animation: 10s track infinite linear;
  background-image: linear-gradient(var(--accent-c) 1px, transparent 1px),
    linear-gradient(90deg, var(--accent-c) 1px, transparent 1px);
  background-position: #{$grid-size / 2} #{$grid-size / 2};
  background-size: $grid-size $grid-size;
  border-color: var(--accent-c);
  border-style: solid;
  border-width: 1px;
}

@keyframes track {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -#{$grid-size} #{$grid-size};
  }
}

a,
button {
  color: inherit;
  outline-style: solid;
  outline-color: transparent;
  outline-width: 2px;
  outline-offset: 4px;
  transition: outline 150ms ease-in-out;

  &:focus,
  &:hover {
    outline-color: var(--color-a);
  }
}
