@import "variables";

.#{$space} {
  box-sizing: border-box;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }
}

@mixin space-size($size-name) {
  .#{$size-name}#{$join}#{$flex-wrap}#{$join}#{$wrap} {
    flex-wrap: wrap;
  }
  .#{$size-name}#{$join}#{$flex-wrap}#{$join}#{$nowrap} {
    flex-wrap: nowrap;
  }
  @each $direction-name, $direction-value in $flex-directions {
    .#{$size-name}#{$join}#{$flex-direction}#{$join}#{$direction-name} {
      display: flex;
      flex-direction: $direction-value;
    }
  }
  .#{$size-name}#{$join}#{$width}#{$join}#{$auto} {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
  .#{$size-name}#{$join}#{$width}#{$join}#{$none} {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }
  @for $i from 1 through 12 {
    .#{$size-name}#{$join}#{$width}#{$join}#{$i} {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% / 12 * #{$i});
    }
  }
}

@include space-size("x");

@each $size-name, $size-value in $sizes {
  @media (min-width: $size-value) {
    @include space-size($size-name);
  }
}
