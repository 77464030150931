$auto: "auto";
$flex-direction: "direction";
$flex-directions: (
  "column": column,
  "row": row,
);
$flex-grow: "grow";
$flex-shrink: "shrink";
$flex-wrap: "wrap";
$font-size: "size";
$font-sizes: (
  4: 8rem,
  3: 4rem,
  2: 2rem,
  1: 1rem,
);
$font-weight: "weight";
$join: "-";
$margin: "margin";
$none: "none";
$nowrap: "nowrap";
$padding: "padding";
$sides: (
  "top": "top",
  "right": "right",
  "bottom": "bottom",
  "left": "left",
);
$sizes: (
  "s": 40rem,
  "m": 60rem,
  "l": 80rem,
);
$space: "space";
$spacing: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 2rem,
  5: 4rem,
);
$text: "text";
$width: "width";
$wrap: "wrap";

// $auto: "a";
// $block: "b";
// $flex-direction: "d";
// $flex-directions: (
//   "c": column,
//   "r": row
// );
// $flex-grow: "g";
// $flex-shrink: "s";
// $flex-wrap: "w";
// $font-size: "s";
// $font-sizes: (
//   4: 8rem,
//   3: 4rem,
//   2: 2rem,
//   1: 1rem
// );
// $font-weight: "w";
// $join: "";
// $margin: "m";
// $none: "n";
// $nowrap: "n";
// $padding: "p";
// $sides: (
//   "top": "t",
//   "right": "r",
//   "bottom": "b",
//   "left": "l"
// );
// $sizes: (
//   "s": 600px,
//   "m": 900px,
//   "l": 1200px
// );
// $spacing: (
//   0: 0,
//   1: 4px,
//   2: 8px,
//   3: 16px,
//   4: 32px,
//   5: 64px
// );
// $text: "t";
// $width: "w";
// $wrap: "w";
