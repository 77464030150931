@import "variables";

@mixin spacing-size($size-name) {
  @each $space-name, $space-value in $spacing {
    .#{$size-name}#{$join}#{$margin}#{$join}#{$space-name} {
      margin: $space-value;
    }
  }
  @each $space-name, $space-value in $spacing {
    .#{$size-name}#{$join}#{$padding}#{$join}#{$space-name} {
      padding: $space-value;
    }
  }
  @each $side-name, $side-value in $sides {
    @each $space-name, $space-value in $spacing {
      .#{$size-name}#{$join}#{$margin}#{$join}#{$side-value}#{$join}#{$space-name} {
        margin-#{$side-name}: $space-value;
      }
    }
    @each $space-name, $space-value in $spacing {
      .#{$size-name}#{$join}#{$padding}#{$join}#{$side-value}#{$join}#{$space-name} {
        padding-#{$side-name}: $space-value;
      }
    }
  }
}

@include spacing-size("x");

@each $size-name, $size-value in $sizes {
  @media (min-width: $size-value) {
    @include spacing-size($size-name);
  }
}
