@import "variables";

.#{$text} {
  font-size: 1rem;
  font-weight: inherit;
  line-height: inherit;
  margin: var(--text-margin, 1rem) 0;
}

@mixin text-size($size-name) {
  @each $font-size-name, $font-size-value in $font-sizes {
    .#{$size-name}#{$join}#{$font-size}#{$join}#{$font-size-name} {
      font-size: $font-size-value;
    }
  }
}

@include text-size("x");

@each $size-name, $size-value in $sizes {
  @media (min-width: $size-value) {
    @include text-size($size-name);
  }
}
